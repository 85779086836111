
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';

import { PublicComponent } from './layout/public/public.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { WelcomeComponent } from './layout/welcome/welcome.component';
import { AuthorizationGuard } from './authorization.guard';


const routes: Routes = [

  {
    path: '',
    pathMatch:'full',
    redirectTo:'welcome',
    data: {
      breadcrumb: {
        info: 'home'
      }
    }
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    data: { breadcrumb: 'Home' }
  },
  {
    path:'portal',
    component: PublicComponent,
    data: { breadcrumb: 'Portal' },
    children:[
      {
        path:'',
        loadChildren: () =>
        import('./modules/public/public.module')
        .then((module) => module.PublicModule), 
      }
    ]
  },
  {
    /**
     * loads authentication module with 
     * child modules/components
     * route : /login/[child route]
    */
    path: 'login',
    component: AuthLayoutComponent,
    children:[
      {
        path:'',
        loadChildren: () =>
        import('./modules/authentication/authentication.module')
        .then((module) => module.AuthenticationModule),
      }
    ]
  },

  /**
   * -------------------------------------------------------
   * System protected routes
   * each protected with authorization guard
   * --------------------------------------------------------
  */

  
  {
    /**
     * loads dashboard module with 
     * child modules/components
     * route : /dashboard/[child route]
    */
    path: 'dashboard',
    component: MainLayoutComponent,
    data: { breadcrumb: 'Dashboard' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module')
          .then(module => module.DashboardModule)
      },
    ],
    canActivate: [AuthorizationGuard],
  },

  {
    /**
     * loads user-profile module with 
     * child modules/components
     * route : /user-profile/[child route]
    */
    path: 'user-account',
    component: MainLayoutComponent,
    data: { breadcrumb: 'My Account' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/user-account/user-account.module')
          .then(module => module.UserAccountModule)
      },
    ],
    canActivate: [AuthorizationGuard],
  },
  
  {
    /**
     * loads System administration module with
     * child modules/components
     * route : /system-settings/[child route]
    */
    path: 'system-settings',
    component: MainLayoutComponent,
    data: { breadcrumb: 'System Settings' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/system-settings/system-settings.module')
          .then(module => module.SystemSettingsModule)
      }
    ],
    canActivate: [AuthorizationGuard],
  },

  {
    /**
     * loads revenue-source module with
     * child modules/components
     * route : /revenue-source/[child route]
    */
    path: 'revenue-source',
    component: MainLayoutComponent,
    data: { breadcrumb: 'Revenue-Source', info: 'home' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/revenue-source/revenue-source.module')
          .then(module => module.RevenueSourceModule)
      }
    ],
    canActivate: [AuthorizationGuard],
  },

  {
    /**
     * loads pda-management module with
     * child modules/components
     * route : /pda-management/[child route]
    */
    path: 'pda-management',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/pda-management/pda-management.module')
          .then(module => module.PdaManagementModule)
      }
    ],
    canActivate: [AuthorizationGuard],
  },

  {
    /**
     * loads reports module with
     * child modules/components
     * route : /reports/[child route]
    */
    path: 'reports',
    component: MainLayoutComponent,
    data: { breadcrumb: 'Reports' },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/reports/reports.module')
          .then(module => module.ReportsModule)
      }
    ],
    canActivate: [AuthorizationGuard],
  },

  {
    path: '**',
    redirectTo: 'welcome',
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
