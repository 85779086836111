<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #appDrawer mode="over" opened="false" class="mat-elevation-z6">
    <div class="sidenav-content"  fxLayout="column" fxLayoutAlign="space-between center">
      <div class="sidenav-item p-5 side-nav-profile" fxLayout="column" fxLayoutAlign="space-around center">
        <img src="../../../assets/imgs/placeholder.jpg" class="avatar" alt="">
        <div class="text-center mt-3 text-white">
          <h5>{{ username }}</h5>
          <h6>{{ email }}</h6>
        </div>
      </div>
      <div class="sidenav-item" *ngIf="newLang == 'Swahili'">
        <mat-nav-list>
          <app-menu-list-item *ngFor="let item of navlinkss" [item]="item"></app-menu-list-item>
        </mat-nav-list>
      </div>
      <div class="sidenav-item"*ngIf="newLang === 'English'">
        <mat-nav-list>
          <app-menu-list-item *ngFor="let item of navlinks" [item]="item"></app-menu-list-item>
        </mat-nav-list>
      </div>
      <div class="footer sidenav-item pt-3 pb-3" fxLayout="column" fxLayoutAlign="center center">
        <p>{{'mainlayout.rights'| translate}} &copy; 2020</p>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="main-content">
    <mat-toolbar color="primary" class="mat-elevation-z6">

      <mat-toolbar-row>

        <button type="button" mat-icon-button (click)="navService.openNav()">
          <mat-icon>menu</mat-icon>
        </button>

        <span class="app-title">
          <app-logo height="23" width="20" name="coat_arms.svg"></app-logo>
          TeRMIS<span class="version">{{'signin.version'| translate}} 1.0.0</span>
        </span>
        <!-- <span class="language"fxLayout="row">
          {{'welcome.language'|translate}}:
          <span class="form-outline">
            <select 
                class="form-control" 
                #selectedLang 
                (change)="switchLang(selectedLang.value)"> 
              <option class="opt" *ngFor="let language of translate.getLangs()" 
                [value]="language"
                [selected]="language === translate.currentLang">
                {{ language }}
              </option>
            </select>
          </span>
        </span> -->
        <span class="spacer"></span>
        <span class=dash>{{dashMessage}}</span>

        <button mat-icon-button [matMenuTriggerFor]="menu1">
          <mat-icon>filter_list</mat-icon>
        </button>

        <mat-menu #menu1="matMenu">
          <button mat-menu-item *ngFor="let child of councils" (click)="onSourceChange(child.councilCode+ '-' + child.councilName)">
            <mat-icon>topic</mat-icon>
            <span>{{child.councilName}}</span>
          </button>
        </mat-menu>
  
        <button mat-icon-button >
          <mat-icon>settings</mat-icon>
        </button>
    
        <button mat-icon-button >
          <mat-icon>notifications</mat-icon>
        </button>

        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>account_circle</mat-icon>
        </button>

        <mat-menu #menu="matMenu"> 
          <button mat-menu-item>
            <mat-icon>account_circle</mat-icon>
            <a routerLink="/user-account">{{'mainlayout.account'|translate}}</a>
          </button>
          <button mat-menu-item>
            <mat-icon>help</mat-icon>
            <span>{{'mainlayout.matrix'|translate}}</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>lock</mat-icon>
            <span>{{'mainlayout.signout'|translate}}</span>
          </button>
        </mat-menu>

      </mat-toolbar-row>
    </mat-toolbar>

    <div class="app-top-space">
      <div class="breadcrumb">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <xng-breadcrumb [separator]="iconTemplate">
                <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info;">
                  <mat-icon *ngIf="info">{{ info }}</mat-icon>
                  <ng-container >{{ breadcrumb }}
                     <!-- {{ breadcrumb.dashboard | translate }}  -->
                  </ng-container>
                </ng-container>
              </xng-breadcrumb>
                <ng-template #iconTemplate>
                  <mat-icon>chevron_right</mat-icon>
                </ng-template>
            </div>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
