import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/modules/shared/services/toast.service';
import { AgentService } from 'src/app/modules/system-settings/_services/agent.service';
import { BillingService } from '../../services/billing.service';
import { DataService } from './dashboard-data-service';

@Component({
  selector: 'app-all-collections-summary',
  templateUrl: './all-collections-summary.component.html',
  styleUrls: ['./all-collections-summary.component.scss']
})
export class AllCollectionsSummaryComponent implements OnInit {

  public today =  new Date();
  public day: any;
  month: any;
  year: any;
  fyear: any;
  total:any;
  subscription: Subscription;

  public collections = [
    {
      type: 'Today Collections',
      subtitle: 'Total Collection ' + this.todayDate(),
      value: 0,
      color: '#ce93d8',
    },
    {
      type: 'Monthly Collections',
      subtitle: 'Total Collection ' + this.todayMonth(),
      value: 0,
      color: '#9c27b0'
    },
    {
      type: 'Yearly Collections',
      subtitle: 'Total Collection FY 2020/2021',
      value: 0,
      color: '#6a1b9a'
    },
    {
      type: 'Collection Target',
      subtitle: 'Collection Target As per Contract',
      value: 0,
      color: '#4a148c'
    }
  ]
  public collectionss = [
    {
      type: 'Makusanyo ya Leo',
      subtitle: 'Jumla ya Makusanyo ' + this.todayDate(),
      value: 0,
      color: '#ce93d8',
    },
    {
      type: 'Makusanyo ya Mwezi',
      subtitle: 'Jumla ya Makusanyo ' + this.todayMonth(),
      value: 0,
      color: '#9c27b0'
    },
    {
      type: 'Makusanyo ya Mwaka',
      subtitle: 'Jumla ya Makusanyo Mwaka wa Fedha 2020/2021',
      value: 0,
      color: '#6a1b9a'
    },
    {
      type: 'Lengo la Makusanyo',
      subtitle: 'Lengo la Ukusanyaji Kulingana na Mkataba',
      value: 0,
      color: '#4a148c'
    }
  ]
  public sessionData: any
  public currentUser: any
  public agentCode: any;
  councilCode: any;
  public newLang: any;


  constructor(
    private translate: TranslateService,
    private dataService: DataService, 
    private _agentSvc: AgentService,
    private toastService: ToastService,
    private billingSvc: BillingService
  ) { 
    if(JSON.parse(localStorage.getItem('userInfo'))){
      this.currentUser = JSON.parse(localStorage.getItem('userInfo'));
      this.agentCode =  this.currentUser.principal.agentCode;
    }
    this.subscription = this.dataService.getMessage().subscribe(message => {
       console.log('message', message.text);
       this.councilCode = message.text;
       //this.councilCode = this.currentUser.principal.councilCode;
       return this.fetchBillPaymentDetails(this.agentCode, this.councilCode);
    });
    translate.use(sessionStorage.getItem('gLang'));
    this.newLang = sessionStorage.getItem('gLang');
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }  

  ngOnInit() {
  if(this.councilCode == 'undefined'){
  this.fetchBillPaymentDetailsDefault(this.agentCode);
     }else{
      this.fetchBillPaymentDetails(this.agentCode, this.councilCode);
    }
  }

  async fetchBillPaymentDetails(agentCode: any, councilCode: any) {
    await this.billingSvc.getRevenueSummary(agentCode, councilCode).subscribe((response) => {
      if(response.code === 6000){
      this.day = response.data.todayCollection
      this.month = response.data.monthCollection
      this.year = response.data.yearlyCollection
      this.total = response.data.councilProjection

      this.collections[0]['value'] = this.day
      this.collections[1]['value'] = this.month
      this.collections[2]['value'] = this.year
      this.collections[3]['value'] = this.total

      this.collectionss[0]['value'] = this.day
      this.collectionss[1]['value'] = this.month
      this.collectionss[2]['value'] = this.year
      this.collectionss[3]['value'] = this.total
      }
     // this.fyear = response['fyear'].toString()
     // this.collections[2]['subtitle'] = 'Total Collection FY ' + this.fyear.substring(0, 4) + "/" + this.fyear.substring(4, this.fyear.length)
    }, (error) => {
    })
  }

  async fetchBillPaymentDetailsDefault(agentCode: any) {
    await this.billingSvc.getAllRevenueSummary(agentCode).subscribe((response) => {
      if(response.code === 6000){
      this.day = response.data.todayCollection
      this.month = response.data.monthCollection
      this.year = response.data.yearlyCollection
      this.total = response.data.councilProjection

      this.collections[0]['value'] = this.day
      this.collections[1]['value'] = this.month
      this.collections[2]['value'] = this.year
      this.collections[3]['value'] = this.total

      this.collectionss[0]['value'] = this.day
      this.collectionss[1]['value'] = this.month
      this.collectionss[2]['value'] = this.year
      this.collectionss[3]['value'] = this.total
      }
     // this.fyear = response['fyear'].toString()
     // this.collections[2]['subtitle'] = 'Total Collection FY ' + this.fyear.substring(0, 4) + "/" + this.fyear.substring(4, this.fyear.length)
    }, (error) => {
    })
  }




  todayDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return dd + '/' + mm + '/' + yyyy;
  }

  todayMonth() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return mm + '/' + yyyy;
  }

 

}
