import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private routeSvc: Router) { }

  /**
   * General function for navigation
   * @param route 
  */
  public navigateTo(route: string){
    this.routeSvc.navigateByUrl(route);
  }
}
