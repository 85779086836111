import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { map, filter, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  public isVisible: boolean;
  public currentRoute: string;
  clientData: any;
  clientName: string;
  toggle: boolean;

  constructor(
    private translate: TranslateService,
    private router: Router, private activatedRoute: ActivatedRoute
    )
     { 
    translate.use(sessionStorage.getItem('gLang'));
    this.toggle = true;
  }

  ngOnInit() {

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .pipe(
        filter(route => route.outlet === 'public'),
        mergeMap(route => route.data),
      )
      .subscribe(event => {
        this.currentRoute = event.title; // title of page
        this.showToolbar(event.showToolbar); // show the toolbar?
      });
  }

  /**
   * 
   * @param route 
  */
  navigateTo(route: string){
    this.router.navigateByUrl(route);
  }

  /**
   * 
   * @param event 
  */
  showToolbar(event: any) {
    if (event === false) {
      this.isVisible = false;
    } else if (event === true) {
      this.isVisible = true;
    } else {
      this.isVisible = this.isVisible;
    }
  }

}
