import { AllCollectionsSummaryComponent } from './../../modules/dashboard/charts/all-collections-summary/all-collections-summary.component';
import { AuthenticationService } from './../../modules/authentication/services/authentication.service';
import { NavigationItem } from './../models/nav.item';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../services/navigation.services';
import { AgentService } from 'src/app/modules/system-settings/_services/agent.service';
import { ToastService } from 'src/app/modules/shared/services/toast.service';
import { DataService } from 'src/app/modules/dashboard/charts/all-collections-summary/dashboard-data-service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  providers: [DataService, AllCollectionsSummaryComponent],
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, AfterViewInit {

  @ViewChild('appDrawer', {static: false}) appDrawer: ElementRef;

  public isMenuOpen = true;
  public currentUser: any;
  public username: string;
  public email: string;
  public councils: any[];
  public sessionData: any
  public agentCode: any;
  public showCouncil:boolean;
  public dashMessage: string;
  public newLang: any;
  public title: string[];
  public sutitle: string;

  navlinks: NavigationItem[] = [
    {
      title: 'Dashboard',
      subTitle: 'Application dashboard',
      iconName: 'dashboard',
      route: '/dashboard'
    },

    // {
    //   title: 'System Settings',
    //   subTitle: 'System Management',
    //   iconName: 'settings',
    //   route: '/system-settings'
    // },

    {
      title: 'User Management',
      subTitle: 'User Management',
      iconName: 'account_circle',
      route: '/system-settings/collector-management'
    },
    
    {
      title: 'PDA Management',
      subTitle: 'PoS Management',
      iconName: 'tablet_android',
      route: '/pda-management'
    },
    {
      title: 'Reports',
      subTitle: 'Various Reports',
      iconName: 'pie_chart',
      route: '/reports'
    }
  ]
  navlinkss: NavigationItem[] = [
    {
      title: 'Dashibodi',
      subTitle: 'Dashibodi ya Maombi',
      iconName: 'dashboard',
      route: '/dashboard'
    },

    // {
    //   title: 'System Settings',
    //   subTitle: 'System Management',
    //   iconName: 'settings',
    //   route: '/system-settings'
    // },

    {
      title: 'Usimamizi wa Mtumiaji',
      subTitle: 'Usimamizi wa Mtumiaji',
      iconName: 'account_circle',
      route: '/system-settings/collector-management'
    },
    
    {
      title: 'Usimamizi wa PDA',
      subTitle: 'Usimamizi wa PoS',
      iconName: 'tablet_android',
      route: '/pda-management'
    },
    {
      title: 'Ripoti',
      subTitle: 'Ripoti Mbalimbali',
      iconName: 'pie_chart',
      route: '/reports'
    }
  ]
  constructor(
    private translate: TranslateService,
    private sharedService: DataService,
    private _agentSvc: AgentService,
    private toastService: ToastService,
    private authenticationSvc: AuthenticationService,
    private router: Router,
    public navService: NavigationService) {

      if(JSON.parse(localStorage.getItem('userInfo'))){
        this.currentUser = JSON.parse(localStorage.getItem('userInfo'));
        this.username = this.currentUser.principal.name;
        this.email = this.currentUser.principal.email;
      }
      this.newLang = sessionStorage.getItem('gLang');
      translate.use(sessionStorage.getItem('gLang'));
    }

  

  ngOnInit() {

    this.sessionData =JSON.parse(localStorage.getItem('userInfo'));
    this.currentUser = this.sessionData.principal;
    this.agentCode = this.currentUser.agentCode;
    
    if(this.currentUser.agentCode){
    this.getSourceInfo();
      this.showCouncil = true;
      this.dashMessage = "DASHBOARD";
      }else{
        this.showCouncil = false;
      }
    }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
  }

  toolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }
  // switchLang(lang: string) {
  //   sessionStorage.removeItem('gLang');
  //   sessionStorage.setItem('gLang', lang);
  //   this.translate.use(lang);
  // }
  logout() {
    this.authenticationSvc.logout();
  }

  public onSourceChange( data: any){
    this.toastService.success('Information', this.translate.instant('mainlayout.change') +data.split("-")[1] , 3000); 
    this.dashMessage = this.translate.instant('breadcrumb.dashMessage')+"-"+ data.split("-")[1].toUpperCase()
    console.log("COUNCIL DATA FROM CHANGE", data);
    this.currentUser.councilCode = data.split("-")[0];
    this.sharedService.sendMessage(data.split("-")[0]);

  }

  public getSourceInfo(){
  
    this._agentSvc.getAgentbyCode(this.agentCode).subscribe((response) => { 
      if(response.code === 6000){  
        this.councils = response.data.agentCouncil
        console.log('sourceInfo', this.councils);  
      } 
    }, (error) => {
      console.error(error);
      this.toastService.error('Information', 'There is an error getting Source information', 6000);
    });
  }

}
