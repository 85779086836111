<div class="background-cover">
  <div class="wrapper">
    <div class="container">
      <div class="row" fxLayout="row" fxLayoutAlign="end center">
        {{'welcome.language'|translate}}:
        <span class="outline">
          <select
              class="control" 
              #selectedLang 
              (change)="switchLang(selectedLang.value)"> 
            <option *ngFor="let language of translate.getLangs()" 
              [value]="language"
              [selected]="language === translate.currentLang">
              {{ language }}
            </option>
          </select>
        </span>
      </div>
      <div class="row" fxLayout="row" fxLayoutAlign="center center">
  
        <div class="col-md-1">
          <div class="logo-container">
            <img src="./../../../../../assets/imgs/coat_arms.svg" alt="">
          </div>
        </div>

        <div class="col-md-10 text-center">
          <h5 class="mb-2 bold">{{'welcome.title1'|translate}}</h5>
          <h6>{{'welcome.title2'|translate}}</h6>
          <!-- <h6>{{'welcome.title3'|translate}}</h6> -->
         
        </div>
         
        <div class="col-md-1">
          <div class="logo-container">
            <!-- <img src="./../../../../../assets/imgs/logo-tarura.PNG" alt=""> -->
            <img src="./../../../../../assets/imgs/coat_arms.svg" alt="">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 text-center">
          <h5>  </h5>
          <h3 class="mt-2">{{'welcome.title'|translate}}</h3>
            <hr>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="spacer"></div>
      <div class="row d-flex justify-content-center">
        <div class="col-md-8">

          <div class="row text-center">
            <div class="col-md-12">
            </div>
          </div>

          <div class="row d-flex justify-content-center">
            <div class="col-md-4">
              <div class="menu-tile mat-elevation-z10" (click)="navigateTo('portal/client-verification')">
                <div class="tile-icon">
                  <img src="../../../../../assets/imgs/manual.svg" alt="">
                </div>
                <div class="tile-action">
                  <div class="title">{{'welcome.applicants'|translate}}</div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="menu-tile mat-elevation-z10" (click)="navigateTo('login')">
                <div class="tile-icon">
                  <img src="../../../../../assets/imgs/admin.svg" alt="">
                </div>
                <div class="tile-action">
                  <div class="title">{{'welcome.agent'|translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="menu-tile mat-elevation-z10" (click)="navigateTo('portal/parking')">
                <div class="tile-icon">
                  <img src="../../../../../assets/imgs/applicant.svg" alt="">
                </div>
                <div class="tile-action">
                  <div class="title">{{'welcome.parking'|translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>