import { Component, OnInit } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Spinkit } from 'ng-http-loader';
import { environment } from 'src/environments/environment.prod';
import { AppModule } from './app.module';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'termis-web-system';
  public spinkit = Spinkit;
  

    constructor() {}
  

  ngOnInit(): void {
   //platformBrowserDynamic().bootstrapModule(AppModule);
    if (environment.production) {
      //enableProdMode();
      // if(window){
      //   window.console.log=function(){};
      // }
    }
  } 
}
