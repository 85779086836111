import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  errorCode: number;
  statusError: any;

  constructor(private authenticationSvc: AuthenticationService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
      const currentClient = JSON.parse(localStorage.getItem("currentClient"));
      const isApiUrl = request.url.startsWith(environment.BASE_API);
       if (request.url.includes('public')) {
          request = request.clone({
           setHeaders: {'x-transfer-key': 'e9f3e572-db87-4eff-9ed6-66922f1f7f24'}
         }); 
       }else if(currentClient && isApiUrl) {
        request = request.clone({
          withCredentials: true,
          setHeaders: { Authorization: `Bearer ${currentClient}`}
        });
      }

      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            return event;
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          let data = {};
          data = {
            reason: error && error.error.reason ? error.error.reason : "",
            status: error.status,
           // causedBy: error.message
          };
          this.authenticationSvc.failedRequest(data);
          return throwError(error);
        })
      );
    }
}
