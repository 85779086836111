import { NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularMaterialModule } from 'src/app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChartsModule } from 'ng2-charts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {BreadcrumbModule} from 'xng-breadcrumb';
import { NgxUploaderModule } from 'ngx-uploader';

import { LogoComponent } from './logo/logo.component';
import { VerifyDialogNewComponent } from '../public/views/verify-dialog-new/verify-dialog-new.component';
import { VerifyDialogExistingComponent } from '../public/views/verify-dialog-existing/verify-dialog-existing.component';
import { GeneralHeaderComponent } from './views/general-header/general-header.component';
import { TransferState } from '@angular/platform-browser';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxPermissionsModule, NgxPermissionsStore, NgxPermissionsService, NgxPermissionsConfigurationService, NgxPermissionsConfigurationStore, NgxRolesService, NgxRolesStore } from 'ngx-permissions';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// loader module
export  function  HttpLoaderFactory(http:  HttpClient) {
  return  new  TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [LogoComponent, GeneralHeaderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedRoutingModule,
    NgbModule,
    NgbCarouselModule,
    AngularMaterialModule,
    FormsModule,
    PdfViewerModule,
    FlexLayoutModule,
    ChartsModule,
    BreadcrumbModule,
    NgxUploaderModule,
    PdfViewerModule,
    TextMaskModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxPermissionsModule.forChild({
      permissionsIsolate: true,
      rolesIsolate: true}),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: (HttpLoaderFactory), 
          deps: [HttpClient]
      }
    })

  ],
  exports:[
    ReactiveFormsModule,
    NgbModule,
    NgbCarouselModule,
    AngularMaterialModule,
    FormsModule,
    PdfViewerModule,
    FlexLayoutModule,
    ChartsModule,
    BreadcrumbModule,
    NgxUploaderModule,
    LogoComponent,
    PdfViewerModule,
    LogoComponent,
    GeneralHeaderComponent,
    PdfViewerModule,
    TextMaskModule,
    NgxMaskModule,
    NgxPermissionsModule,
    TranslateModule
  ],
  providers:[
    NgxPermissionsService,
    NgxPermissionsStore,
    NgxPermissionsConfigurationService,
    NgxPermissionsConfigurationStore,
    NgxRolesService,
    NgxRolesStore,
    TranslateService
  ],
  entryComponents: []
})
export class SharedModule { }
