<mat-sidenav-container  class="sidenav-container" fullscreen >
  <mat-sidenav-content>

    <mat-toolbar color="primary" class="mat-elevation-z6">
      <mat-toolbar-row class="container-fluid">
        <span class="app-title">
          <app-logo height="23" width="20" name="coat_arms.svg"></app-logo>
          TeRMIS<span class="version">{{'signin.version'|translate}} 1.0.0</span>
        </span>
        <span class="spacer"></span>
        
        <button mat-icon-button class="mr-3">
          <mat-icon>language</mat-icon>
        </button>
        
        <button mat-icon-button (click)="navigateTo('/welcome')">
          <mat-icon>home</mat-icon>
        </button>

      </mat-toolbar-row>
      
    </mat-toolbar>
    <div class="app-top-space">
      
      <div class="breadcrumb">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <xng-breadcrumb [separator]="iconTemplate">
                <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info;">
                  <mat-icon *ngIf="info">{{ info }}</mat-icon>
                  <ng-container >{{ breadcrumb }}</ng-container>
                </ng-container>
              </xng-breadcrumb>
                <ng-template #iconTemplate>
                  <mat-icon>chevron_right</mat-icon>
                </ng-template>
            </div>
          </div>
        </div>
      </div>

      <router-outlet></router-outlet>
      <footer class="mt-5 footer hidden-xs-down">
        <p>© 2020.{{'public.hati'| translate}}</p>
    </footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>