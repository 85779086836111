import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../modules/shared/shared.module';

import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { PublicComponent } from './public/public.component';

import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { WelcomeComponent } from './welcome/welcome.component';


@NgModule({
  declarations: [
    AuthLayoutComponent,
    MainLayoutComponent,
    MenuListItemComponent,
    PublicComponent,
    WelcomeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
})
export class LayoutModule { }
