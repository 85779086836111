import { councilType } from './../../system-settings/_models/item.model';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";




@Injectable({
    providedIn: 'root'
  })

export class BillingService {



  private readonly baseUrl: string = `${environment.BASE_API}/termis-billing-service/api`;

  private readonly budgetUrl: string = `${environment.BASE_API}/termis-budget-service/api`;

  constructor(private httpClientSvc: HttpClient) { }


    public getRevenueSummary(agentCode: string, councilCode: string): Observable<any> {
        return this.httpClientSvc.get<any>(`${this.baseUrl}/agent-council-dashboard/${agentCode}/${councilCode}`);
      }

      public getAllRevenueSummary(agentCode: string): Observable<any> {
        return this.httpClientSvc.get<any>(`${this.baseUrl}/agent-council-dashboard/${agentCode}`);
      }
    
      public getAllAgentsOrdered(): Observable<any>{
        return this.httpClientSvc.get<any>(`${this.baseUrl}/agents/ordered/4`);
      }
    
      public getScanningSummary(gentCode: any, councilCode: any): Observable<any> {
        return this.httpClientSvc.get<any>(`${this.baseUrl}/agent-scan-summary/${gentCode}/council-Code/${councilCode}`);
      }

       
      public getScanningSummaryDefault(gentCode: any): Observable<any> {
        return this.httpClientSvc.get<any>(`${this.baseUrl}/agent-scan-summary/${gentCode}`);
      }
    
      public totalRevenue(): Observable<any> {
        return this.httpClientSvc.get<any>(`${this.budgetUrl}/budget/top-revenue`);
      }

}