import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AgentModel } from '../_models/agents.model';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  private baseUrl: string = `${environment.BASE_API}/termis-uaa-service/api`;

  constructor(private _httpClientSvc: HttpClient) { }

  /**
   * Return a list of All agents
  */
  public getAllAgents(): Observable<any>{
    return this._httpClientSvc.get<AgentModel[]>(`${this.baseUrl}/agents`);
  }
  
  // public getSourcebyAgentId(agentId:string): Observable<any>{
  //   return this._httpClientSvc.get<AgentModel>(`${this.baseUrl}/agent-council/agent/${agentId}`);
  // }

  public getAgentbyCode(code:string): Observable<any>{
    return this._httpClientSvc.get<AgentModel>(`${this.baseUrl}/agents/agent-code/${code}`); //
  }


  public getAllAgentsUser(): Observable<any>{
    return this._httpClientSvc.get<AgentModel[]>(`${this.baseUrl}/users/agents`);
  }

  
  /**
   * Create agent
   * @param agentPayload 
  */
  public saveAgent(payload: AgentModel): Observable<any>{
    return this._httpClientSvc.post<AgentModel>(`${this.baseUrl}/agents`, payload);
  }

  /**
   * verify Agent by TIN number
   * @param tinNumber 
   * @param payload 
  */
  public verifyAgentTin(tinNumber: string, payload: AgentModel): Observable<any>{
    return this._httpClientSvc.post<AgentModel>(`${this.baseUrl}/agents/tinNumber/${tinNumber}`, payload);
  }

  /**
   * Create Agent's Account
   * @param id 
   * @param payload 
  */
  public createAgentAccount(id: string, payload: AgentModel): Observable<any>{
    return this._httpClientSvc.post<AgentModel>(`${this.baseUrl}/agents/account/${id}`, payload);
  }

  /**
   * Find Agent By Id
   * @param agentId 
  */
  public findAgentById(agentId:string): Observable<any>{
    return this._httpClientSvc.get<AgentModel>(`${this.baseUrl}/agents/${agentId}`);
  }

  /**
   * Update Agent Details
   * @param agentId 
   * @param payload 
  */
  public editAgent(agentId:string, payload: AgentModel): Observable<any>{
    return this._httpClientSvc.put<AgentModel>(`${this.baseUrl}/agents/${agentId}`, payload);
  }
}
