import { ApplicantTypeModel } from './../app/modules/public/models/applicant.model';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  CLIENTID: "termis-client",
  CLIENTSECRET: "123456",
  production: false,
  //BASE_API: 'https://termis.tarura.go.tz:6003',
   BASE_API_LIVE: 'https://termis.tamisemi.go.tz',
   BASE_API:  'https://termis.tamisemi.go.tz'
};

/*s
 * For easier debugging in development m175ode, you can import the following filee
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
