
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { ToastService } from './../../shared/services/toast.service';
import { StorageKey } from './../../shared/models/storage.model';
import { StorageService } from '../../shared/services/storage.service';
import { BnNgIdleService } from 'bn-ng-idle';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private readonly baseUrl: string =  `${environment.BASE_API}/termis-uaa-service`;

  token: string;
  refreshToken: string;
  currentUser: any;
  expire: any;
  errorCode: any;
  redirectUrl: string;
  sessionData: Object;
  permissions: any;
  tokenExpiryTime: any;

  constructor(
    private toastSvc: ToastService,
    private httpClientSvc: HttpClient,
    private storageSvc: StorageService,
    private router: Router,
    private bnIdle: BnNgIdleService
  ) {
    this.token = this.storageSvc.read(StorageKey.AUTH_TOKEN) || '';
    this.refreshToken = this.storageSvc.read(StorageKey.REFRESH_TOKEN) || '';
    this.currentUser = this.storageSvc.read(StorageKey.CURRENT_USER) || '';
    this.expire = this.storageSvc.read(StorageKey.EXPIRE) || '';
  }

  public getToken(): string {
    return this.token;
  }

  public failedRequest(err: any): void {
    this.errorCode = err.status;
    const title = 'Access Denied';
    if (this.errorCode === 401) {
      this.toastSvc.error(title, 'Sorry, Unauthorized Access', 9000);
      localStorage.removeItem('currentClient');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('expireTime');
      localStorage.removeItem('EXPIRE');
      window.sessionStorage.clear();
      this.router.navigateByUrl('/welcome');
    }

    if (this.errorCode === 404) {
      const message = 'Service Temporarily Unavailable';
      this.toastSvc.error(message, err.causedBy, 9000);
    }

    if (this.errorCode === 500) {
      const message =
        'Service Temporarily Unavailable please contact System Administrator';
      const title = 'Connection Failure';
      this.toastSvc.error(title, message, 9000);
    }
    if (this.errorCode === 504) {
      const title = 'Connection Failure';
      const message = 'Service Temporarily Unavailable';
      this.toastSvc.error(title, message, 9000);
    }
  }

  /**
   * signin services
   * @param username 
   * @param password 
  */
  login(username: string, password: string): Observable<any> {
  
    const body = `username=${encodeURIComponent(
      username
    )}&password=${encodeURIComponent(password)}&grant_type=password`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: 'Basic ' + btoa(environment.CLIENTID + ':' + environment.CLIENTSECRET),
      notoken: 'notoken'
    });
    return this.httpClientSvc.post<any>(`${this.baseUrl}/oauth/token`, body, {headers})
      .pipe(
        map(user => {
          if (user && user.access_token) {
            this.tokenExpiryTime = JSON.stringify(user.expires_in);
            localStorage.setItem('currentClient',JSON.stringify(user.access_token));
            localStorage.setItem('refreshToken',JSON.stringify(user.refresh_token));
            localStorage.setItem('EXPIRE', JSON.stringify(user.refresh_token));
            localStorage.setItem('expireTime', JSON.stringify(user.expires_in));
            // this.authenticatedClient();
            this.watchingTokenExpiry();
          }
          return user;
        })
      );
  }

  
  /**
   * logout
  */
  public logout() {
    this.token = '';
    this.currentUser = '';
    this.expire = '';
    this.refreshToken = '';
    this.storageSvc.remove(StorageKey.AUTH_TOKEN);
    this.storageSvc.remove(StorageKey.CURRENT_USER);
    this.storageSvc.remove(StorageKey.REFRESH_TOKEN);
    this.storageSvc.remove(StorageKey.EXPIRE);
    localStorage.removeItem('currentClient');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expireTime');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('newAccount');
    window.sessionStorage.clear();
    this.router.navigate(['welcome']);
  }

  public authenticatedClient() {
    this.token = JSON.parse(localStorage.getItem('currentClient'));
    if (this.token) {
      this.httpClientSvc
        .get(`${this.baseUrl}/api/users/detail?access_token=${this.token}`)
        .subscribe(response => {
          this.sessionData = response;
          this.currentUser = this.sessionData['principal'];
          this.toastSvc.success('Hello', 'Welcome ' + this.currentUser.name, 4000);
          localStorage.setItem('userInfo', JSON.stringify(response));

          if(this.currentUser.newAccount){
            localStorage.setItem('newAccount', this.currentUser.newAccount);
            this.router.navigateByUrl('/dashboard');
          }else{
            this.router.navigateByUrl('/dashboard');
          }
        });
    }
  }

  public watchingTokenExpiry() {
    this.bnIdle.startWatching(this.tokenExpiryTime)
      .subscribe((isTimedOut: boolean) => {
        if (this.isUserloggedIn()) {
          this.logout();
        }
      });
  }

  public expireTime(expireDuration: any) {
    return Number(new Date(Date.now() + expireDuration));
  }

  public isLogged(): boolean {
    try {
      const expire = this.storageSvc.read(StorageKey.EXPIRE);
      return !!expire;
    } catch (e) {
      return false;
    }
  }

  public isUserloggedIn(): boolean {
    if (this.token !== '') {
      return true;
    } else {
      return false;
    }
  }

  public user(): any {
    return this.storageSvc.read(StorageKey.CURRENT_USER);
  }

  public userInfo(): Observable<any> {
    const user = localStorage.getItem('userInfo');
    return JSON.parse(user);
  }

  public fetchAllUsers(): Observable<any>{
    return this.httpClientSvc.get<any>(`${this.baseUrl}/api/users`);
  }

  public getCouncilLevelById(id: any): Observable<any>{
    return this.httpClientSvc.get<any>(`${this.baseUrl}/api/users/accounts-switch/id/${id}`);
  }

}
