import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {


  defaultSize: any;
  defaultName: string;


  @Input('width') logoWidth:any;
  @Input('height') logoHeight:any;
  @Input('name') logoName:string;

  constructor() {
    this.defaultName = './assets/imgs/coat_arms.svg';
  }

  ngOnInit() {
    this.defaultName = `./assets/imgs/${this.logoName}`;
  }

}
