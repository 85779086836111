import { AuthenticationService } from './../../modules/authentication/services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/modules/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  isLoggedIn: boolean;
  newLang: any;

  constructor(
    public translate: TranslateService,
    private authSvc: AuthenticationService,
    private commonSvc: CommonService) {
      this.isLoggedIn = this.authSvc.isLogged();
      translate.addLangs(['Swahili', 'English']);
      this.newLang = 'Swahili';
      sessionStorage.setItem('gLang', this.newLang);// setting
      translate.setDefaultLang(sessionStorage.getItem('gLang'));
    }

  ngOnInit() {
    this.isLoggedIn ? this.navigateTo('/dashboard') : this.navigateTo('/welcome');
  }

  navigateTo(route: string){
    this.commonSvc.navigateTo(route);
  }

  switchLang(lang: string) {
    sessionStorage.removeItem('gLang');
    sessionStorage.setItem('gLang', lang);
    this.translate.use(lang);
  }
}
